let EstadosSolicitudesExtended = [
    "Creando solicitud", // 0 
    "Recibiendo ofertas", // 1
    "Finalizada sin adjudicación", //2
    "Cancelada por el cliente", // 3
    "Cancelada por el transportista", // 4
    "Agendada", // 5
    "Finalizada sin ofertas", // 6
    "No Realizado", // 7
    "Esperando transferencia de linea", // 8
    "En curso a dirección de inicio", // 9
    "Cargando, a la espera de guía de despacho",    // 10
    "Cargando, a la espera de fotos de carga", // 11
    "En curso a dirección de termino", // 12
    "Entregado, a la espera de guía de despacho",   // 13
    "Entregado, a la espera de fotos de carga", // 14
    "Finalizada"] // 15


const EstadosSolicitudes = [
    "Borrador ",//0
    "Ofertando",//1
    "Cancelada",//2
    "Canceladas",//3
    "Canceladas",//4
    "Agendada",//5
    "Cancelada",//6
    "No Realizado",//7
    "Agendada",//8
    "En Curso",//9
    "En Curso",//10
    "En Curso",//11
    "En Curso",//12
    "En Curso",//13
    "En Curso",//14
    "Terminadas",//15
    "Borrador"//16
]

const EstadosClientes = [
    "Operativo",
    "No operativo",
    "Bloqueado",
    "Suspendido",
    "Eliminado"
]

const EstadosTrabajador = [
    "Operativo",
    "No operativo",
    "Bloqueado",
    "Suspendido"
]

let TipoCamion = [
    "",
    "Camión Simple",
    "Camión Simple con Remolque",
    "Tracto Camión",
    "Camión 3/4",
    "Furgones",
    "Boxer",
    "Camionetas",
    "Maquinaria"
]

let TipoCamionFiltrado = {
    0: "Seleccione", // 0 NO QUITAR!!!!
    1: "Camión Simple",
    2: "Camión Simple con Remolque",
    3: "Tracto Camión",
    //4 : "Camión 3/4",
    5: "Furgones",
    //6 : "Boxer",
    //7 : "Camionetas" ,
    8: "Maquinaria"
}


const TRANSPORTISTAS_GPS_VISIBLE = [
    '13314480-3', // BENJAMIN OLMAZABAL
    '76389285-9', // TRANSPORTES LAZO
    '76367987-k', // TRANSPORTES ROMERO UNO SPA
    '15403112-k', // LISSETTE CAROLINA BLANCO RIOS
    '96874380-5', // Linsa S.A
    '77566070-8', // FAMIN LTDA
    '77033419-5', // TRANSMAK SPA
    '77207593-6', // TRANSPORTES MORENA SPA
    '76730411-0', // LOGISTICA SANTA MARGARITA SPA
    '77664935-k', // TRANSPORTES POLCAR SPA
    '77731601-k', // TRANSPORTES CADIBA SPA
    '7956928-3', // Carlos Ernesto Díaz Barrera
    '77443568-9',// AZABACHE TRANSPORTES Y DISTRIBUCION SPA
    '77118201-1',// TRANSPORTE HECTOR LAVANDERO LTDA
    '77428453-2', // JR LOPEZ TRANSPORTE Y LOGISTICA SPA
    '76936445-5', // INVERSIONES RIBA SPA
    '916608830-5', // TRANSPORTES SUBCARGO
    '77577649-8', // TRANSPORTES ROBERTO PATRICIO OBREQUE AVENDAÑO EIRL
    '76518527-0', // COMERCIAL ANGLOR SPA,
    '77014622-4',  //TRANSPORTES RENE MAURICIO MORA CALDERON EIRL
    '9840189-k', //ROBERTO DEL CARMEN POBLETE VILLAGRA
    '9120422-3', //CLAUDIO IVAN PENA CISTERNAS
    '76439529-8', //TRANSPORTES MARCOS JAVIER OÑATE BELTRAN EIRL
    '76973144-k', //TRANSPORTES F&J
    '77018401-0', //T-LOWS SPA
    '77090443-9', //Transportes Salazar SPA
    '20393933527', //GOLDSTAR CONTRATISTAS GENERALES S.A.C.
    //    '20601237581'
    '20455870969', //AGZ TRANSPORTES S.A.C.
    '20130447679', //GA SOLUCIONES LOGISTICAS S.A.C.
    '20393933527', //GOLDSTAR CONTRATISTAS GENERALES S.A.C.
    '20536619985', //J&P TRANSSER S.A.C.
    '20565608585' //TRANSPORTES Y SERVICIOS GRUPO TELLO

]

const MotivoCancelacion = {
    0 : "--Seleccione una opción (obligatorio)--",
    1 : "Cambio de Fecha de carga",
    2 : "Cambio de Fecha de descarga",
    3 : "Cambio en el tipo de carga",
    4 : "Cambio en el tonelaje de carga",
    5 : "Cambio en el lugar de origen de carga",
    6 : "Cambio en el lugar de destino de carga ",
    7 : "Cancelación de orden servicio por mandante (Obra o proveedor)",
    8 : "Cancelación por no cumplimiento de requisitos del transportista",
    9 : "Cancelación por no presentación al lugar de carga por parte del transportista",
    10 : "Cancelación por atraso en lugar de carga por parte del transportista",
    11 : "Cancelación por problema técnico del transportista",
    12 : "Cancelación por compromiso previo del transportista",
    101 : "Problemas mecánicos", 
    102 : "Cambios en las Políticas de la solicitud", 
    103 : "Cambios en Fecha/Hora/Destino",  
    104 : "Flete Falso", 
    105 : "No cumplo con los requisitos" 
}

const TRANSPORTISTAS_FINANZAS_PERU_VISIBLE = [
    "20601237581", 
    "10731719107", 
    "20543724778", 
    "20600039483", 
    "10257682132", 
    "20554821104", 
    "20455870969", 
    "20610583882", 
    "20603114460", 
    "20601137276", 
    "20424092941", 
    "20538191761", 
    "20606460695", 
    "20612352071", 
    "20552747624", 
    "20601174384", 
    "20552874570", 
    "20556340847", 
    "20612061034", 
    "10800013386", 
    "20601137276", 
    "10461149885", 
    "20550380235", 
    "20608940228", 
    "20565777697", 
    "20537571463", 
    "20536263439",
    "20512932313",// TRANSPORTES TRES S.R.L.
    "10771438976",//PEREZ GUTIERREZ ABEL ROMULO
    "20603842996",// MONTACARGAS Y GRUAS JL E.I.R.L
    "10094845624" // AQUINO MARCALAYA BLANCA 
]
const TRANSPORTISTAS_FINANZAS_VISIBLE = [
    "77627886-6",
    "15619185-k",
    "77657286-1",
    "77302619-k",
    "76376433-8",
    "17942513-0",
    "77416499-5",
    "52003309-2",
    "76133780-7",
    "76432854-k",
    "76742475-2",
    "77192572-3",
    "76865290-2",
    "27238923-3",
    "77143991-8",
    "77447805-1",
    "13107819-6",
    "77674196-5",
    "76253942-k",
    "77476324-4",
    "15624172-5",
    "8500321-6",
    "77757992-4",
    "77110925-k",
    "77276194-5",
    "77534755-4",
    "13314480-3",
    "77183206-7",
    "77469226-6",
    "9726044-3",
    "76660821-3",
    "9658580-2",
    "9371153-k",
    "15433141-7",
    "9120422-3",
    "77057689-k",
    "76518527-0",
    "76841074-7",
    "77325677-2",
    "77451278-0",
    "77236788-0",
    "76920843-7",
    "76713913-6",
    "76703173-4",
    "77344009-3",
    "77561202-9",
    "77130231-9",
    "77434144-7",
    "16893837-3",
    "15009051-2",
    "14451566-8",
    "18991087-8",
    "7956928-3",
    "77081324-7",
    "77038562-8",
    "76201196-4",
    "15353696-1",
    "26722164-2",
    "22563135-2",
    "15031904-8",
    "25988165-k",
    "78963380-0",
    "76768365-0",
    "77528461-7",
    "76496176-5",
    "76906154-1",
    "77655497-9",
    "77540036-6",
    "9518261-5",
    "77529628-3",
    "52004766-2",
    "5682206-2",
    "77673416-0",
    "77361682-5",
    "76771489-0",
    "76071434-8",
    "76181913-5",
    "76049098-9",
    "17657071-7",
    "77566070-8",
    "77311072-7",
    "77479317-8",
    "77742465-3",
    "16894962-6",
    "19086905-9",
    "76238965-7",
    "76098566-k",
    "76167481-1",
    "12339097-0",
    "11458271-9",
    "77723592-3",
    "77517419-6",
    "77752515-8",
    "77099906-5",
    "76815409-0",
    "77475154-8",
    "76848469-4",
    "77356261-k",
    "77060534-2",
    "77340865-3",
    "76718441-7",
    "10465192-5",
    "77411543-9",
    "76751301-1",
    "77190458-0",
    "77755675-4",
    "77693739-8",
    "16531048-9",
    "77294879-4",
    "77420923-9",
    "77214813-5",
    "77436317-3",
    "99526550-8",
    "76982397-2",
    "77115449-2",
    "77472445-1",
    "77727675-1",
    "77556005-3",
    "77627902-1",
    "77484007-9",
    "77337574-7",
    "76936445-5",
    "76606001-3",
    "77293058-5",
    "77006258-6",
    "77196162-2",
    "77130978-k",
    "76215349-1",
    "77257459-2",
    "76890002-7",
    "76859446-5",
    "14427309-5",
    "77406583-0",
    "12359654-4",
    "12983805-1",
    "10782034-5",
    "11825071-0",
    "77107186-4",
    "9167902-7",
    "76454059-k",
    "76700978-k",
    "77061489-9",
    "15700781-5",
    "12406296-9",
    "77020108-k",
    "13278205-9",
    "7667395-0",
    "23637769-5",
    "11505534-8",
    "77428453-2",
    "18884558-4",
    "25791948-k",
    "16348312-2",
    "16192081-9",
    "14259469-2",
    "14418259-6",
    "14313355-9",
    "10395089-9",
    "77676163-k",
    "77202716-8",
    "76724447-9",
    "77518516-3",
    "77677515-0",
    "77158417-9",
    "76730411-0",
    "76038367-8",
    "76921749-5",
    "77085646-9",
    "77603732-k",
    "6207000-5",
    "9924042-3",
    "76759811-4",
    "13300794-6",
    "16010011-7",
    "14549602-0",
    "10811508-4",
    "11513562-7",
    "77469220-7",
    "13558771-0",
    "17986963-2",
    "6719321-0",
    "13621157-9",
    "77067945-1",
    "76983290-4",
    "77499651-6",
    "9801314-8",
    "3699051-1",
    "8746143-2",
    "77431575-6",
    "76064886-8",
    "77226353-8",
    "76979620-7",
    "10537933-1",
    "6784319-3",
    "15778975-9",
    "17302412-6",
    "76011921-0",
    "77763709-6",
    "15607948-0",
    "76934967-7",
    "19452483-8",
    "77710533-7",
    "18373439-3",
    "77034055-1",
    "17832643-0",
    "14176813-1",
    "15930025-0",
    "14070278-1",
    "76901398-9",
    "77362612-k",
    "77197025-7",
    "76680623-6",
    "76467649-1",
    "77042951-k",
    "76368478-4",
    "77229285-6",
    "76854094-2",
    "12480043-9",
    "9840189-k",
    "8763155-9",
    "17431461-6",
    "76903542-7",
    "77007766-4",
    "76935794-7",
    "6771892-5",
    "77290225-5",
    "26163240-3",
    "8652985-8",
    "77150243-1",
    "76267002-k",
    "17770206-4",
    "77447946-5",
    "12035585-6",
    "11477868-0",
    "16045752-k",
    "10189184-4",
    "77499592-7",
    "77486969-7",
    "77074907-7",
    "77644236-4",
    "89421800-2",
    "77509998-4",
    "77206614-7",
    "76472922-6",
    "77139147-8",
    "77300827-2",
    "76093123-3",
    "77632132-k",
    "76293436-1",
    "77666962-8",
    "76783418-7",
    "77529798-0",
    "76690333-9",
    "76059440-7",
    "78130200-7",
    "77143995-0",
    "76002189-k",
    "76072020-8",
    "76137357-9",
    "76761306-7",
    "77337679-4",
    "76459330-8",
    "77275344-6",
    "76629378-6",
    "77344591-5",
    "76194093-7",
    "77492707-7",
    "77672446-7",
    "77129985-7",
    "76026788-0",
    "77724403-5",
    "76976550-6",
    "76892633-6",
    "76771014-3",
    "77204889-0",
    "77793825-8",
    "77005263-7",
    "76686770-7",
    "77391082-0",
    "76546135-9",
    "76093217-5",
    "76389743-5",
    "76219693-k",
    "76272738-2",
    "77018401-0",
    "77054659-1",
    "76108925-0",
    "6432961-8",
    "77387292-9",
    "77383706-6",
    "77043058-5",
    "77581574-4",
    "76389039-2",
    "76833119-7",
    "77033419-5",
    "77524179-9",
    "77100428-8",
    "77489884-0",
    "76511258-3",
    "77628779-2",
    "77624357-4",
    "77530835-4",
    "76586546-8",
    "76947212-6",
    "77222494-k",
    "76202058-0",
    "77297217-2",
    "76920055-k",
    "17307109-4",
    "15058035-8",
    "77099766-6",
    "10260656-6",
    "4004488-4",
    "76419046-7",
    "76873259-0",
    "77096039-8",
    "77574764-1",
    "77033556-6",
    "76875018-1",
    "77064074-1",
    "77102902-7",
    "76053534-6",
    "76486266-k",
    "13128907-3",
    "77261822-0",
    "77520057-k",
    "77560035-7",
    "77497179-3",
    "76488447-7",
    "77386624-4",
    "77678867-8",
    "77010462-9",
    "10629365-1",
    "76940009-5",
    "77728778-8",
    "17970649-0",
    "11932226-k",
    "77424526-k",
    "77773705-8",
    "77039958-0",
    "76918533-k",
    "76438700-7",
    "8405077-6",
    "77496257-3",
    "7467988-9",
    "77148667-3",
    "17965714-7",
    "77689234-3",
    "77731601-k",
    "76904567-8",
    "77196296-3",
    "76981709-3",
    "77693723-1",
    "78492420-3",
    "77311551-6",
    "76904289-k",
    "77085391-5",
    "76550128-8",
    "77505228-7",
    "77686909-0",
    "77559126-9",
    "76866997-k",
    "77547874-8",
    "77165935-7",
    "77056277-5",
    "77178918-8",
    "76867724-7",
    "77578807-0",
    "13205142-9",
    "77426007-2",
    "77329211-6",
    "77116232-0",
    "76702966-7",
    "77045356-9",
    "76968437-9",
    "77158823-9",
    "76804394-9",
    "77432646-4",
    "77087271-5",
    "76035281-0",
    "77438231-3",
    "77429269-1",
    "76091642-0",
    "76886696-1",
    "77302047-7",
    "77077923-5",
    "76426148-8",
    "77099737-2",
    "77267706-5",
    "77547105-0",
    "77256518-6",
    "77525763-6",
    "76938502-9",
    "76973144-k",
    "77363175-1",
    "77228516-7",
    "77646828-2",
    "77508183-k",
    "77300224-k",
    "14000710-2",
    "76360975-8",
    "77330371-1",
    "76660451-k",
    "8207268-3",
    "76813268-2",
    "77554838-k",
    "77087221-9",
    "76754300-k",
    "77296464-1",
    "77241418-8",
    "77593205-8",
    "77646507-0",
    "77081062-0",
    "76913787-4",
    "76223695-8",
    "77148294-5",
    "5977566-9",
    "76534211-2",
    "77129235-6",
    "77695294-k",
    "77285236-3",
    "76846876-1",
    "77616334-1",
    "77744283-k",
    "76694232-6",
    "77271861-6",
    "77445924-3",
    "77763507-7",
    "14348009-7",
    "77508797-8",
    "77338647-1",
    "77548119-6",
    "77723402-1",
    "7248961-6",
    "77558793-8",
    "77731793-8",
    "76679490-4",
    "77629836-0",
    "77374202-2",
    "79695460-4",
    "76995760-k",
    "77464163-7",
    "77001348-8",
    "76932141-1",
    "76389285-9",
    "77511911-k",
    "76012918-6",
    "76860920-9",
    "77373202-7",
    "77418067-2",
    "77046305-k",
    "77036666-6",
    "77534538-1",
    "77306179-3",
    "76990397-6",
    "77183112-5",
    "76439529-8",
    "76220030-9",
    "76929996-3",
    "86234800-1",
    "76092478-4",
    "77427851-6",
    "77618821-2",
    "76306129-9",
    "77322249-5",
    "77580713-k",
    "77458814-0",
    "77008385-0",
    "6849850-3",
    "76154422-5",
    "77207593-6",
    "77243323-9",
    "77551441-8",
    "76221153-k",
    "77432636-7",
    "76944918-3",
    "76948493-0",
    "76101069-7",
    "77453158-0",
    "77491909-0",
    "77281468-2",
    "77350018-5",
    "76822895-7",
    "77474213-1",
    "76018663-5",
    "76577785-2",
    "77188783-k",
    "77664935-k",
    "77595475-2",
    "77252005-0",
    "77646886-k",
    "77063556-k",
    "77341136-0",
    "77763256-6",
    "77434147-1",
    "77014622-4",
    "76513517-6",
    "77577649-8",
    "76525891-k",
    "77103802-6",
    "76367987-k",
    "77313189-9",
    "77530965-2",
    "77647329-4",
    "77608618-5",
    "77667611-k",
    "77120871-1",
    "77383007-k",
    "77364773-9",
    "76801451-5",
    "77706149-6",
    "8664207-7",
    "76136922-9",
    "77487533-6",
    "77636527-0",
    "77131441-4",
    "77374091-7",
    "77548983-9",
    "77073761-3",
    "77243364-6",
    "77654834-0",
    "77392663-8",
    "77396668-0",
    "76401032-9",
    "76116161-k",
    "77545014-2",
    "9528721-2",
    "76485225-7",
    "76727215-4",
    "77373819-k",
    "76336122-5",
    "76978757-7",
    "77636392-8",
    "77396657-5",
    "76055200-3",
    "76446365-k",
    "76171497-k",
    "77465028-8",
    "77720607-9",
    "77618668-6",
    "77932520-2",
    "77309234-6",
    "76448401-0",
    "77198438-k",
    "77691613-7",
    "77525594-3",
    "76801527-9",
    "77271986-8",
    "76512926-5",
    "77015696-3",
    "77343174-4",
    "77492684-4",
    "77390279-8",
    "76464701-7",
    "77398663-0",
    "77312908-8",
    "77158254-0",
    "76482394-k",
    "76924526-k",
    "77759282-3",
    "77157233-2",
    "76576281-2",
    "11868394-3",
    "76745671-9",
    "76638028-k",
    "77086522-0",
    "16732439-8",
    "76180265-8",
    "77686639-3",
    "77136161-7",
    "77212512-7",
    "76785566-4",
    "76240601-2",
    "77090706-3",
    "76060284-1",
    "77077859-k",
    "77596925-3",
    "76048727-9",
    "76706768-2",
    "76184592-6",
    "77360286-7",
    "77813577-9",
    "77808762-6",
    "76933801-2",
    "76162428-8",
    "50889830-4",
    "77365135-3",
    "77968610-8",
    "76038539-5",
    "77511644-7",
    "76417118-7",
    "76081286-2",
    "14567558-8",
    "14114405-7",
    "76536243-1",
    "18905171-9",
    "7459077-2",
    "77738525-9",
    "5588670-9",
    "20226920-6",
    "18132840-1",
    "77682929-3",
    "19283546-1",
    "77714141-4",
    "13245258-k",
    "77780095-7",
    "77682488-7",
    "77326538-0",
    "77433132-8",
    "76969612-1",
    "9012873-6",
    "76755335-8",
    "77487155-1",
    "77693847-5",
    "77758559-2",
    "16989596-1",
    "18398400-4",
    '44444444-4', //Transportista de prueba (SUBCARGO SPA)
    '916608830-5',
    '16611662-7',
    '96874380-5', // LINSA S.A
    '20601237581',
    '76982397-2', // INGENIERIA ERR SPA
    '13314480-3', // BENJAMIN OLMAZABAL
    '11868394-3', // TRANSPORTES HUGO VILLA
    '25791948-k', // JUAN CARLOS HERNANDEZ MALDONADO
    '7459077-2', // YOLANDA CABRERA MENDEZ
    '77434144-7', // CREACIONES E INVERSIONES FRABRITODO LTDA
    '15403112-k', // LISSETTE CAROLINA BLANCO RIOS
    '77120871-1', // TRANSPORTES SALOMON CARRASCO M EIRL
    '77486969-7', // SERVICIO DE TRANSPORTES LUNA BARRERA EIRL
    '76921749-5', // LOGISTICA Y TRANSPORTES ME LTDA
    '77574764-1', // TRANSPORTE NAZARENO
    '77731601-k', // TRANSPORTES CADIBA SPA
    '77099766-6', // TRANSPORTE GONZAM ROSA ZAMBRANO ROA EIRL
    '76389285-9', // TRANSPORTES LAZO
    '77043058-5', // TRANS TURBO CHILE SPA
    '77559126-9', // TRANSPORTES CGC
    '9840189-k', // ROBERTO DEL CARMEN POBLETE VILLAGRA
    '76934967-7', // NBC SERVICIOS SPA
    '77932520-2', // TRANSPORTES VALENZUELA SILVA LTDA
    '77644236-4', // SERVICIO J&S SPA
    '77356261-k', // GRUPO UNION SPA
    '14000710-2', // TRANSPORTES FLABIO SAN MARTIN
    '76116161-k', // TRANSPORTES STROKER
    '77188783-k', // TRANSPORTES PMG
    '76936445-5', // INVERSIONES RIBA SPA
    '77060534-2', // Gruaman Spa
    '77556005-3', // INVERSIONES GONZALEZ LEIVA SPA
    '77465028-8', // TRANSPORTES TUCUPA
    '76576281-2', // Transeffort E.I.R.L
    '77432636-7', // TRANSPORTES NICOLAS GOMEZ MAMANI Y RUBEN GOMEZ MAMANI LTDA
    '77373819-k', // TRANSPORTES TEMPLANZA SERVICIOS SPA
    '14427309-5', // IVAN FUENTES PEREZ
    '76513517-6', // TRANSPORTES ROBERTO DAVID ALVARADO COSIO EIRL
    '77511911-k', // TRANSPORTES LBS SPA
    '77038562-8', // Comercial Silva Swanson SpA
    '77101272-8', // MFP Ingeniería & Logística 
    '77226353-8', // MULTISERVICIOS CASAMARO SPA
    '11554829-8', // ROBERTO MALDONADO CESPEDES 
    '13205142-9', // TRANSPORTES CORNEJO
    '76860920-9', // TRANSPORTES LIRA DIVISION CAMIONES LTDA
    '76920055-k', // TRANSPORTE ESTERO ALTO SPA
    '77529798-0', // SERVICIOS Y SOLUCIONES GENERALES B&B SPA
    '77015696-3', // TRANSPORTES Y SERVICIOS MOVISER SPA
    '86234800-1', // TRANSPORTES MARISOL SPA
    '77484969-6', // transportes piri spa 
    '77074907-7', // SERVICIO GRUA PLUMA SPA
    '96874380-5', // Linsa S.A
    '77118201-1', // TRANSPORTE HECTOR LAVANDERO LTDA 
    '77624357-4', // TRANSPORTE CLAUDIO MONTECINOS EIRL
    '77261822-0', // TRANSPORTES  Y SERVICIOS ISOP SPA
    // '76190855-3', // TRANSPORTES KAP LTDA
    '76713913-6', // COMERCIALIZADORA Y FORESTAL LOS RIOS SPA
    '76202058-0', // TRANSPORTE DE MAQUINARIAS BINDERMAQ SpA
    '76360975-8', // TRANSPORTES FRANCISCO HERRERA EIRL
    '77429269-1', // TRANSPORTES DOÑA ELENA SPA
    '4753922-6', // Carlos Alberto Duijvesteijn Barja
    '77148294-5', // TRANSPORTES GUSTAVO ABARCA LTDA
    '76167481-1', // FUENTES Y COMPAÑIA LTDA
    '76700978-k', // JJ SERVICIOS LOGISTICOS LTDA
    '77033419-5', // TRANSMAK SPA
    '77165935-7', // TRANSPORTES CHRISTOPHER ANDRES LAVIN GARCIA EIRL
    '77271986-8', // TRANSPORTES Y MAQUINARIAS VM SPA
    '77325677-2', // COMERCIAL Y TRANSPORTES ESCAL SPA
    '77396668-0', // TRANSPORTES SR SPA
    '76768365-0', // DIFER SOLUCIONES SPA
    '9120422-3', // CLAUDIO IVAN PENA CISTERNAS
    '13107819-6', // ANGELA GOMEZ LUMAN
    '77364773-9', // TRANSPORTES SAN JUAN E HIJOS SPA
    '77492684-4', // TRANSPORTES YAMAR SPA
    '76525891-k', // TRANSPORTES RODRIGO FUENTES ESTAY EIRL
    '7467988-9', // TRANSPORTES BLANCA ROMERO
    '77190458-0', // HZ TRANSPORTES Y SERVICIOS SPA 
    '26163240-3', // Riviere Logistics spa
    '76973144-k', // TRANSPORTES F&J
    '76518527-0', // COMERCIAL ANGLOR SPA
    '77034055-1', // ONECARGO SPA
    '77435687-8', // DMC SERVICIOS INTEGRALES SPA 
    '76367987-k', // TRANSPORTES ROMERO UNO SPA
    '77033556-6', // TRANSPORTE SAN SANTIAGO Y AGRICOLA SPA
    '77202716-8', // KATLENEXPRESSTECHNOLOGY SPA
    '18373439-3', // Nibaldo Josue Ticuna Garcia
    '17307109-4', // TRANSPORTE F CONTRERAS
    '76904567-8', // TRANSPORTES CADISA
    '76026788-0', // SOCIEDAD TRANSPORTES GUERRERO LTDA.
    '77100428-8', // TRANSPORTE ALEJANDRO MAMANI EIRL
    '14114405-7', // XIMENA BEATRIZ GUERRERO AROSTICA
    '77063556-k', // TRANSPORTES RAL
    '15009051-2', // CRISTIAN PATRICIO CORNEJO VERGARA
    '76846876-1', // TRANSPORTES INDOMITO SPA
    '77007766-4', // RYA IMPORTADORA AUTOMOTRIZ SPA
    '16010011-7', // MARCELO ANDRES AGUILERA FUENTEALBA
    '76629378-6', // SOCIEDAD DE TRANSPORTES HYD LIMITADA
    '76012918-6', // TRANSPORTES LEIVA, BEAS Y COMPAÑIA LTDA
    '77087221-9', // TRANSPORTES G SANTIBAÑEZ
    '77360286-7', // Transportes Quezada Aguilar
    '76389039-2', // TRANSCAP
    '76913787-4', // TRANSPORTES GRAMAQ
    '76745671-9', // Transporte Monte Belo Spa
    '14259469-2', // JUAN ORTIZ
    '17832643-0', // PABLO WLADIMIR HERRERA ZUÑIGA
    '76542011-3', // TRANSPORTES RODRIGUEZ
    '9243116-9', // Transportes Jorge Jofre 
    '77300224-k', // TRANSPORTES FJ SPA
    '76873259-0', // TRANSPORTE MANCHE SPA
    '76093217-5', // Sociedad de Transportes Oxa Limitada
    '77243323-9', // TRANSPORTES MOZO SPA
    '77041759-7', // TRANSPORTES LINCO E HIJOS 
    '15700781-5', // JORGE ANDRES BRAVO OCHOA
    '76154422-5', // TRANSPORTES MODESTO DAMIAN SEGOVIA ARAYA EIRL
    '6207000-5', // LUCIANO HILARIO LARAMA CAQUISANE
    '77036666-6', // TRANSPORTES LUIS QUIROZ EIRL
    '77365135-3', // VASGUZ SPA
    '77554838-k', // TRANSPORTES FUENTES SPA
    '76577785-2', // TRANSPORTES PINEIDA SPA
    '76055200-3', // TRANSPORTES TRAMOSIL
    '77548119-6', // TRANSPORTES JOHN SANTAELLA EIRL
    '77046305-k', //  TRANSPORTES LOPEZ SPA
    '26722164-2', // DANIEL ELI DAVILA MARQUINA
    '77206614-7', // SERVICIOS BARBARA FARIAS FARIAS EIRL
    '77001348-8', // TRANSPORTES LATORRE LIMITADA
    '77752515-8', // GLOBAL SERVICES J&L SPA
    '76093123-3', // SERVICIOS INTEGRALES CACCIUTTOLO LTDA
    '76184592-6', // Transportes Papasideris Ltda
    '8500321-6', // ARTURO HAGEDORN HERMOSILLA
    '77428453-2', // JR LOPEZ TRANSPORTE Y LOGISTICA SPA
    '7956928-3', // Carlos Ernesto Díaz Barrera
    '9518261-5', // EDITH EUGENIA PAVEZ MIRANDA
    '76771489-0', // EMPRESA SALFATE SPA
    '77484007-9', // INVERSIONES LOGOS & KYRIOS SPA
    '77243364-6', // TRANSPORTES SEPULVEDA SPA
    '11932226-k', // TRANSPORTES ALVARO VILLEGAS
    '76730411-0', // LOGISTICA SANTA MARGARITA SPA
    '15507534-1', // TRANSPORTE CRISTIAN BELTRAN 
    '77142605-0', // CONSTRUCCIONES ELVERLIN VARILLAS BAZAN EIRL
    '14418259-6', // JUAN PABLO CASTRO
    '76724447-9', // KRIEGER TRANSPORTES ESPECIALES
    '76180265-8', // Transportes Bulnes Ltda
    "76865290-2",//ALVARO BARRAZA Y CIA LTDA
    "77469226-6",//BYM CREACIONES SPA
    "76703173-4",//CONSTRUCTORA IKONSTRUO LIMITADA
    "77540036-6",//ECAFRA SPA
    "76049098-9",//Elis Ltda
    "11447513-0",//HECTOR ALEJANDRO CARRASCO GARCES 
    "77388618-0",//INVERSIONES R&J SPA
    "77006258-6",//INVERSIONES TSALACH SPA
    "77257459-2",//INVERTRANS SPA
    "76011921-0",//Miguel Diaz y Cia Ltda.
    "15607948-0",//NATHALIE VARGAS GUERRA
    "19452483-8",//NICOLAS ALEJANDRO ANTEQUERA PAEZ
    "19283546-1",//salomon edgardo carcamo acosta
    "76472922-6",//SERVICIOS BRIOCCELLI LTDA
    "77666962-8",//SERVICIOS LOGISTICOS THUNDERCATS SPA
    "76771014-3",//SRC TELECOMUNICACIONES
    "10629365-1",//TRANSPORTES ALFREDO GARRIDO
    "76791689-2",//TRANSPORTES ANGELA BLANCO 
    "76438700-7",//TRANSPORTES BALAP LTDA
    "78492420-3",//TRANSPORTES CARGOTRANS TARAPACA S.A
    "77578807-0",//TRANSPORTES CONTRERAS MARTINEZ SPA
    "77646828-2",//TRANSPORTES FAUNES SPA
    "77593205-8",//TRANSPORTES GIACOMOZZI
    "77596925-3",//Transportes JAOB
    "76048727-9",//Transportes Luisa Vitali Limitada
    "76439529-8",//TRANSPORTES MARCOS JAVIER OÑATE BELTRAN EIRL
    "77647329-4",//TRANSPORTES RUBIO ARENAS SPA
    "76336122-5",//TRANSPORTES TERRACOP
    '76858429-k',
    '77124111-5',
    '76998899-8',
    '76420061-6',
    '76918765-0',
    '15017917-3',
    '76843510-3',
    '77066302-4'

]

//CLIENTES Y TRABAJADORES UNACEM
const CLIENTES_GPS_VISIBLE = [
    '20608552171', //CLIENTE UNACEM
    '06366874',
    '1234500000',
    '76916220',
    '09869257',
    '47226547',
    '07624996',
    '21133627',
    '70254354',
    '20417573705'
]

const SALFA = [
    "96684600-3", //Empresa de Montajes Industriales SALFA S.A.
    "76929210-1", //EMPRESA DE MANTENCIONES Y SERVICIOS SALFA S.A.
    "77112779-7", //SALFA SERVICIOS SPA
    "96962430-3", //CONSTRUCTORA NOVATEC EDIFICIOS S.A.
    "86856700-7", //CONSTRUCTORA NOVATEC S.A.
    "76053696-2", //CONSTRUCTORA NOVAL LTDA
    "76363031-5", //CONSTRUCTORA BRISAS DE BATUCO S.A.
    "91300000-5", //Tecsa S.A
    "93659000-4", //CONSTRUCTORA SALFA S.A.
    "96967010-0", //MAQUINARIAS Y EQUIPOS MAQSA S.A.
    "96557400-k", //GEOVITA S.A.
    "79723890-2", //ICEM S.A.
    "83109000-6", //EMPRESA CONSTRUCTORA FE GRANDE S.A.
    "96954630-2",  //Salfacorp Comercial S.A
]

const UNACEM = [
    "20608552171",
]

const TipoDeUnidad = [
    "kilogramos",
    "litros",
    "toneladas",
    "unidades",
    "m3"
]


//deprecated
const RETORNOS_VISIBLE = [
    '44444444-4', //Transportista de prueba (SUBCARGO SPA)
    '66666666-6', // Cliente de prueba (SUBCARGO SPA)
    'subcargoadmin', // creo que es obvio
    '5647643-1' //Padre ausente
]

const TIPO_DE_ESTRUCTURA_DE_CARGA = [
    "No Aplica", //0
    "Caja Cerrada Carga Seca", //1
    "Caja Cerrada Carga Refrigerada",//2
    "Jaula Ganadera",//3
    "Jaula Enlonada",//4
    "Plataforma",
    "Plataforma para Contenedores",
    "Cama Baja",
    "Carga Granel (Productos Alimenticios)",
    "Trailer Tanque (Para liquidos / Gases / Asfaltos / Quimicos)",
    "Porta Vehiculos", // 10
    "Batea",
    "Camion Tolva (Escombros / Aridos / Materiales de Construcción)",
    "Porta Maderas",
    "Mixer",
    "No Aplica", // 15
    "Sider",
    "Pluma",
    "Plataforma Rebajada",//18
    "Plataforma Parapeto",
    "Plataforma Extensible",//20
    "Plataforma Grua",
    "Cama Baja Con guinche", //22
    "Cama Baja Cuello Cisne",
    "Cama Baja Modulo",//24
    "Cama Baja Palote",
    "AMPLIROLL",//26
    "MODULOS",//27
    "Plataforma Grúa hidráulica", // 28
    "Cama cuna", // 29
    "Excavadora", //30
    "Aljibe"
]

// const TIPO_DE_ESTRUCTURA_DE_CARGA_PERU = [
//     "No Aplica", //0
//     "Caja Cerrada Carga Seca", //1
//     "Caja Cerrada Carga Refrigerada",//2
//     "Jaula Ganadera",//3
//     "Jaula Enlonada",//4
//     "Plataforma",
//     "Plataforma para Contenedores",
//     "Cama Baja",
//     "Carga Granel (Productos Alimenticios)",
//     "Trailer Tanque (Para liquidos / Gases / Asfaltos / Quimicos)",
//     "Porta Vehiculos", // 10
//     "Encapsulado",
//     "Camion Tolva (Escombros / Aridos / Materiales de Construcción)",
//     "Porta Maderas",
//     "Mixer",
//     "No Aplica", // 15
//     "Sider",
//     "Pluma",
//     "Plataforma Rebajada",//18
//     "Plataforma Parapeto",
//     "Plataforma Extensible",//20
//     "Plataforma Grua",
//     "Cama Baja Con guinche", //22
//     "Cama Baja Cuello Cisne",
//     "Cama Baja Modulo",//24
//     "Cama Baja Palote",
//     "AMPLIROLL",//26
//     "MODULOS",//27
//     "Plataforma Grúa hidráulica", //28
//     "Bombona" // 29
// ]
const TiposDeEstructuraDeCarga = [
    [],
    ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "12", "13", "14", "15", "16", "17", "26"], //Camión Simple
    ["0"],
    ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "13", "15", "16", "27", "29"],  // Tracto Camión
    ["1", "2", "5", "15"],
    ["0", "1", "2"],
    ["0"],
    ["0"],
    ["30"]
]

const TiposDeEstructuraDeCarga2 = [
    ["0"], //0
    ["0", "2"], //1
    ["0", "1"], //2
    ["0"], //3
    ["0"], //4
    ["0", "6", "16", "17", "18", "19", "20", "21", "28"], //5  Plataforma
    ["0", "5"], //6
    ["0", "22", "23", "24", "25"], //7
    ["0"], //8
    ["0"], //9
    ["0"],//10 //10
    ["0"], //11
    ["0"], //12
    ["0"], //13
    ["0"], //14
    ["0"], //15
    ["0", "5"],//16 Despues//16
    ["0", "5",],//17     Despues//17   PLUMA
    ["0"], //18
    ["0"], //19
    ["0"], //20
    ["0"], //21
    ["0"], //22
    ["0", "5"],//23
    ["0", "5"],//24
    ["0"], //25
    ["0"], //26
    ["0"], //27
    ["0"], //28
    ["0"], //29
    ["0"], //30
    ["0"], //31
]
const ColoresEstados = [
    "#FFFFFF", //No Operativo
    "#65B891", //Operativo
]

const ColoresSolicitudes = [
    "#969696", //gris borrador
    "#8AC4FF", //celeste ofertando
    "#B3001B", //rojo cancelado
    "#B3001B", //rojo Cancelada
    "#B3001B", //rojo Cancelada
    "#E3B505", //amarillo Agendada
    "#B3001B", //rojo Cancelada
    "#000000", //amarillo Agendada
    "#E3B505", //amarillo Agendada
    "#27529C", //azul En Curso
    "#27529C", //azul En Curso
    "#27529C", //azul En Curso
    "#27529C", //azul En Curso
    "#27529C", //azul En Curso
    "#27529C", //azul En Curso
    "#65B891",//verde Terminada
    "#6B7280" //borrador cancelado
]

const ListaCargas = [
    "SELECCIONE", // 0 NO QUITAR!!!!
    "ACEITES",//1
    "ARIDOS",//2
    "ACEITES Y GRASAS COMESTIBLES",//3
    "ALFOMBRAS",//4
    "ALGODÓN",//5
    "ALIMENTACIÓN ANIMAL",//6
    "ACERO",//7
    "ARTÍCULOS DE ACERO",//8
    "ARTÍCULOS DE METALES BÁSICOS",//9
    "METALES BASICOS",//10
    "ARTÍCULOS TEXTILES EXCLUYENDO VESTUARIO DEMARCA",//11
    "TEXTILES",//12
    "AZÚCAR REFINADA",//13
    "BARRAS DE ALUMINIO",//14
    "ALUMINIO",//15
    "BATERÍAS Y CELDAS",//16
    "CELDAS DE CARGA",//17
    "BEBIDAS",//18
    "BEBIDAS ALCOHÓLICAS",//19
    "ALCOHOLES",//20
    "BICICLETAS",//21
    "CAFÉ",//22
    "CARGA GENERAL DE RETAIL Y TIENDAS ELECTRÓNICAS",//23
    "CARNE FRESCA ",//24
    "CARNE POLLO-CERDO (FRESCA)-CARNES (CONGELADA y REFRIGERADA)",//25
    "POLLO",//26
    "CERDO",//27
    "CAUCHO",//28
    "CEMENTO",//29
    "CERÁMICO",//30
    "CEREALES",//31
    "CINC Y SUS MANUFACTURAS",//32
    "COBRE Y SUS MANUFACTURAS ",//33
    "COJINES",//34
    "COLCHONES",//35
    "COMBUSTIBLES",//36
    "COMIDA",//37
    "CONFITERÍA",//38
    "CONGELADORES Y OTROS EQUIPOS DE REFRIGERACIÓN O CONGELACIÓN",//39
    "CORCHO",//40
    "CUERO",//41
    "EQUIPOS ELÉCTRICOS",//42
    "FERTILIZANTES",//43
    "FRUTA FRESCA",//44
    "FRUTOS SECOS Y NUECES",//45
    "MAQUINARIA",//46
    "HIERRO O ACERO",//47
    "INGREDIENTES FARMACÉUTICOS",//48
    "INSTRUMENTOS MUSICALES",//49
    "INSUMOS",//50
    "JUEGOS Y ARTÍCULOS DEPORTIVOS",//51
    "ARTICULOS DEPORTIVOS ",//52
    "JUGOS DE FRUTAS (INCLUIDO EL MOSTO DE UVA) Y JUGOS DE VEGETALES",//53
    "JUGUETES ",//54
    "LANA",//55
    "LECHE (PROCESADA",//56
    "LIBROS",//57
    "REVISTAS",//58
    "MADERA ASERRADA O ASTILLADA LONGITUDINALMENTE CORTADA O PELADA",//59
    "MATERIALES INDUSTRIALES Y DE PRODUCCIÓN",//60
    "METALES Y MINERALES EN GENERAL NO PRECIOSO (ORO",//61
    "MINERALES DE COBRE Y SUS CONCENTRADOS",//62
    "MUEBLES",//63
    "PAPEL HIGIÉNICO",//64
    "PAPEL Y CARTÓN",//65
    "CARTON",//66
    "PESCADO",//67
    "PESCADO CONGELADO",//68
    "PETRÓLEO",//69
    "PLÁSTICOS Y SUS MANUFACTURAS",//70
    "PLATA",//71
    "PRODUCTOS ANIMALES (COMESTIBLES) ",//72
    "PRODUCTOS DE CEREALES",//73
    "CEREALES",//74
    "PRODUCTOS DE PAN",//75
    "PAN",//76
    "PRODUCTOS DEL MAR",//77
    "PRODUCTOS FARMACÉUTICOS",//78
    "PRODUCTOS FOTOGRÁFICOS",//79
    "PRODUCTOS QUÍMICOS",//80
    "PULPA DE MADERA",//81
    "REFRIGERADORES",//82
    "ROCAS MINERALES NO PRECIOSO",//83
    "ROPA DE CAMA",//84
    "ROPA DE MARCAS",//85
    "ROPA DE TRABAJO Y SEGURIDAD",//86
    "SEMILLAS ",//87
    "TÉ",//88
    "TELAS (ELEGANTES",//89
    "TELAS DE ALGODÓN",//90
    "TRANSFORMADORES ELÉCTRICOS",//91
    "VARILLAS Y PERFILES",//92
    "VEGETALES Y FRUTAS",//93
    "ZAPATOS Y ZAPATILLAS ",//94
    "ESTRUCTURA METÁLICA",//95
    "CAÑERÍAS",//96
    "MOLDAJE",//97
    "ANDAMIOS",//98
    "CONTENEDORES",//99
    "ESTRUCTURA",//100
    "MATERIALES",//101 
    "FIERRO",//102
    "PREFABRICADOS",//103
    "MODULO (OFICINA/HABITACIONAL)",//104
    "AMPLIROLL",//105
    "MULTIBUCKET",//106
    "MÓDULOS",//107
    "ESCOMBROS",//108
    "DESECHOS/RESIDUOS",//109
]


var CargaPeligrosa = [
    undefined,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    true,
    true,
    false,
    true,
    true,
    true,
    false,
    true,
    true,
    true,
    true,
    true,
    false,
    false,
    false,
    false,
    false,
    true,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    true,
    true,
    false,
    false,
    true,
    false,
    true,
    true,
    false,
    true,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    true,
    false,
    false,
    false,
    false,
    true,
    true,
    false,
    false,
    false,
    true,
    false,
    false,
    false,
    false,
    true,
    true,
    true,
    false,
    false,
    false,
    false,
    false,
    true,
    false,
    true,
    false,
    false,
    false,
    false,
    false,
    false,
    true,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
]
const TipodeEjeArray = [
    "-",
    "200",
    "210",
    "300",
    "310",
    "311",
    "320",
    "321",
    "340",
    "400",
    "410",
    "411",
    "420",
    "422",
    "440",
    "450",
    "451",
    "460",
    "461",
    "470",
    "477",
    "500",
    "510",
    "511",
    "520",
    "522",
    "530",
    "531",
    "532",
    "533",
    "540",
    "570",
    "577",
    "630",
    "631",
    "632",
    "633",
    "650",
    "651",
    "660",
    "662",
    "670",
    "677",
    "680",
    "681",
    "687",
    "688",
    "690",
    "692",
    "697",
    "699",
    "770",
    "771",
    "772",
    "773",
    "780",
    "781",
    "787",
    "788"
]

export {
    EstadosSolicitudes,
    EstadosClientes,
    EstadosTrabajador,
    TipoCamion,
    TipoCamionFiltrado,
    TIPO_DE_ESTRUCTURA_DE_CARGA,
    TRANSPORTISTAS_GPS_VISIBLE,
    TRANSPORTISTAS_FINANZAS_VISIBLE,
    TRANSPORTISTAS_FINANZAS_PERU_VISIBLE,
    CLIENTES_GPS_VISIBLE,
    RETORNOS_VISIBLE,
    ListaCargas,
    CargaPeligrosa,
    ColoresEstados,
    ColoresSolicitudes,
    EstadosSolicitudesExtended,
    TiposDeEstructuraDeCarga,
    TiposDeEstructuraDeCarga2,
    TipodeEjeArray,
    SALFA,
    UNACEM,
    TipoDeUnidad,
    MotivoCancelacion
}